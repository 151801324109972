<template>
  <!-- <div> -->
  <img
    v-if="img"
    :src="config.public.apiBase + img.attributes?.url"
    :srcset="
      config.public.apiBase + img.attributes?.formats?.thumbnail?.url + ' 245w,'
        + config.public.apiBase + img.attributes?.formats?.small?.url + ' 500w,'
        + config.public.apiBase + img.attributes?.formats?.medium?.url + ' 750w,'
        + config.public.apiBase + img.attributes?.formats?.large?.url + ' 1000w,'
        + config.public.apiBase + img.attributes?.url + ' ' + img.attributes?.width + 'w'
    "
    :sizes="sizes"
    :alt="img.attributes?.alternativeText"
    class="object-cover"
  >
  <!-- <nuxt-img :src="config.public.apiBase + img.attributes?.url" loading="lazy"
            sizes="sm:100vw md:100vw lg:100vw" /> -->
  <!-- </div> -->
</template>

<script lang="ts" setup>
import type { UploadFileEntity } from '~/src/gql/graphql'

const config = useRuntimeConfig()

defineProps<{
  img: UploadFileEntity
  sizes?: string
}>()
</script>
